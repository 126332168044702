import axios from 'axios'

const api = axios.create({
  // baseURL: window.appConfig.api_url,
})

export const STATUS = {
  idle: 'idle',
  loading: 'loading',
  reloading: 'reloading',
  loaded: 'loaded',
  failed: 'failed',
}

export const getSecret = async id => {
  try {
    let response = await api.get(`/api/v1/secrets/${id}`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const storeSecret = async ({ secret, expires_views, expires_at }) => {
  try {
    let response = await api.post('/api/v1/secrets', { secret, expires_views, expires_at })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

/*
export const deleteSecret = async id => {
  try {
    let response = await api.delete(`/api/v1/secrets/${id}`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}
*/

export const trackView = async id => {
  try {
    let response = await api.post(`/api/v1/secrets/${id}/view`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}
