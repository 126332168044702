/** @jsxImportSource @emotion/react */
import React from 'react'
import styled from '@emotion/styled'
import Clipboard from 'clipboard'
import * as api from './api'
import { decrypt } from './crypto'
import { css, Box, Row, Column, Button } from './ui'

const ScrollableBox = styled(Box)`
  & {
    overflow: scroll;
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox
  }
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`

const extractPassword = ({ hash }) => hash.replace('#', '') || null

export default class Pickup extends React.Component {
  state = {
    copied: false,
    revealed: false,
    revealedSecret: null,
    storedSecret: {
      status: api.STATUS.idle,
      secret: null,
    },
  }

  loadStoredSecret = async () => {
    let [err, response] = await api.getSecret(this.props.id)

    if (err) {
      this.setState({
        storedSecret: { status: api.STATUS.failed, secret: null },
      })

      return
    }

    this.setState({
      storedSecret: { status: api.STATUS.loaded, secret: response.data.secret },
    })
  }

  revealStoredSecret = async () => {
    let password = extractPassword(window.location)
    let revealedSecret = decrypt(password, JSON.stringify(this.state.storedSecret.secret))

    if (!revealedSecret) {
      // @todo
      console.error('failed to decrypt secret')
      return
    }

    this.setState({ revealed: true, revealedSecret }, async () => {
      this.setupClipboard()

      await api.trackView(this.props.id)
    })
  }

  setupClipboard = () => {
    this.clipboard = new Clipboard(this.refCopyUrlButton, {
      target: () => this.refShareUrlInput,
    })
  }

  componentDidMount() {
    this.setState(
      { storedSecret: { status: api.STATUS.loading, secret: null } },
      this.loadStoredSecret
    )
  }

  render() {
    let { copied, revealed, storedSecret, revealedSecret } = this.state

    if (revealed) {
      return (
        <Column bg="white">
          <Row justifyContent="center" pb={3} fontWeight="600">
            SHARE CONTENT
          </Row>

          <Row border="1px solid #ccc" borderRadius={3} p={3}>
            <ScrollableBox>
              <pre ref={(r) => (this.refShareUrlInput = r)}>{revealedSecret}</pre>
            </ScrollableBox>
          </Row>

          <Row justifyContent="flex-end" py={3}>
            <Button
              ref={(r) => (this.refCopyUrlButton = r)}
              width={100}
              variant="secondary"
              onClick={(e) =>
                this.setState({ copied: true }, () =>
                  setTimeout(() => this.setState({ copied: false }), 3500)
                )
              }
            >
              {copied ? '✔' : 'COPY'}
            </Button>
          </Row>
        </Column>
      )
    }

    return (
      <>
        <Column minHeight={120}>
          {storedSecret.status === api.STATUS.loaded && (
            <>
              <Row justifyContent="center" pb={3}>
                After revealing a share's content, it may no longer be viewable.
              </Row>

              <Row justifyContent="center" alignItems="center">
                <Button
                  width={160}
                  disabled={storedSecret.status === api.STATUS.loading}
                  onClick={this.revealStoredSecret}
                >
                  REVEAL CONTENT
                </Button>
              </Row>
            </>
          )}

          {storedSecret.status === api.STATUS.failed && (
            <Box textAlign="center" lineHeight="1.5">
              <span
                css={css`
                  font-size: 16px;
                  font-weight: 600;
                `}
              >
                Whoops!
              </span>
              <br />
              This share is invalid or not longer exists.
              <br />
              It may have reached its maximum views or expired.
            </Box>
          )}
        </Column>
      </>
    )
  }
}
