// Mostly lifted from https://github.com/BillKeenan/dead-drop-python/blob/master/deadWeb/js/deaddrop.js

import sjcl from 'sjcl'
import MersenneTwister from 'mersenne-twister'

export const makePwd = () => {
  sjcl.random.startCollectors()

  for (let i = 0; i < 5; i++) {
    // throw away a couple
    sjcl.random.randomWords(1)
  }

  let m = new MersenneTwister(sjcl.random.randomWords(1))
  sjcl.random.stopCollectors()

  let text = ''
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 15; i++) {
    text += possible.charAt(Math.floor(m.random() * possible.length))
  }

  return text
}

export const encrypt = (pw, content) => {
  try {
    let crypt = sjcl.encrypt(pw, content)
    return crypt
  } catch (err) {
    console.error('Error encrypting data')
    return false
  }
}

export const decrypt = (pw, data) => {
  try {
    return sjcl.decrypt(pw, data)
  } catch (err) {
    return false
  }
}
