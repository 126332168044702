/** @jsxImportSource @emotion/react */
import React from 'react'
import DatePicker from 'react-datepicker'
import addDays from 'date-fns/addDays'
import * as api from './api'
import { makePwd, encrypt } from './crypto'
import { Box, Row, Column, Button, Input, Textarea, Select, datePickerStyles } from './ui'
import Stored from './stored'

const makeShareableUrl = ({ id, password }) => `${window.location.origin}/pickup/${id}#${password}`

export default class Entry extends React.Component {
  state = {
    stored: false,
    content: '',
    views: '3',
    expires: addDays(new Date(), 14),
    shareableUrl: null,
    storedSecret: null,
  }

  storeSecret = async () => {
    let { content, views, expires } = this.state

    let password = makePwd()
    let result = encrypt(password, content)

    let [err, response] = await api.storeSecret({
      secret: JSON.parse(result),
      expires_views: parseInt(views, 10),
      expires_at: expires.toISOString(),
    })

    if (err) {
      console.error(err)
      return
    }

    let id = response.data.id

    this.setState({
      stored: true,
      shareableUrl: makeShareableUrl({ id, password }),
      storedSecret: {
        status: api.STATUS.loaded,
        secret: response.data,
      },
    })
  }

  reset = () => {
    this.setState({
      stored: false,
      content: '',
      views: '3',
      expires: addDays(new Date(), 14),
      shareableUrl: null,
      storedSecret: null,
    })
  }

  render() {
    let { stored, content, views, expires, shareableUrl, storedSecret } = this.state

    if (stored) {
      return <Stored shareableUrl={shareableUrl} storedSecret={storedSecret} reset={this.reset} />
    }

    return (
      <>
        <Column>
          <Box width={1} pb={3}>
            <Textarea
              placeholder="enter message"
              value={content}
              onChange={(e) => this.setState({ content: e.target.value })}
            />
          </Box>

          <Column>
            <Box fontSize={11} color="#aaa" pb={2}>
              MESSAGE EXPIRATION
            </Box>

            <Row justifyContent="space-around" pb={3}>
              <Column width={4 / 12}>
                <Box fontSize={11} pb={2}>
                  Number of Views
                </Box>

                <Select value={views} onChange={(e) => this.setState({ views: e.target.value })}>
                  {[...Array(10).keys()].map((i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Select>
              </Column>

              <Column width={5 / 12} mx={2} css={datePickerStyles}>
                <Box fontSize={11} pb={2}>
                  Expires At
                </Box>

                <DatePicker
                  selected={expires}
                  customInput={<Input />}
                  onChange={(e) => this.setState({ expires: e })}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption={null}
                />
              </Column>

              <Column width={3 / 12} justifyContent="flex-end">
                <Button onClick={this.storeSecret}>SHARE</Button>
              </Column>
            </Row>
          </Column>
        </Column>
      </>
    )
  }
}
