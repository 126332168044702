/** @jsxImportSource @emotion/react */
import React from 'react'
import Clipboard from 'clipboard'
import distanceInWordsToNow from 'date-fns/formatDistanceToNow'
import { COLORS, css, Box, Row, Column, Button, Input } from './ui'

window.distanceInWordsToNow = distanceInWordsToNow

const timeDifference = (expiresTS) => {
  if (!expiresTS) return 'unknown'

  return distanceInWordsToNow(new Date(expiresTS))
}

export default class Entry extends React.Component {
  state = {
    copied: false,
  }

  componentDidMount() {
    this.clipboard = new Clipboard(this.refCopyUrlButton, {
      target: () => this.refShareUrlInput,
    })
  }

  componentWillUnmount() {
    this.clipboard.destroy()
  }

  render() {
    let { copied } = this.state
    let { shareableUrl, storedSecret, reset } = this.props

    return (
      <>
        <Column bg="white" border="1px solid #ccc" borderRadius={3} px={4} py={4} mb={3}>
          <Row justifyContent="center" pb={3}>
            <Box fontSize={14} fontWeight="600">
              SUCCESS!
            </Box>
          </Row>

          <Row pb={4}>
            Your private message will be accessible for a limited time, and number of viewings at
            the following URL.
          </Row>

          <Row pb={4} position="relative">
            <Input
              ref={(r) => (this.refShareUrlInput = r)}
              width={1}
              value={shareableUrl}
              readOnly
              css={css`
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-color: ${COLORS.blue};
                border-right: none;
              `}
            />

            <Button
              ref={(r) => (this.refCopyUrlButton = r)}
              width={100}
              variant="secondary"
              css={css`
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              `}
              onClick={(e) =>
                this.setState({ copied: true }, () =>
                  setTimeout(() => this.setState({ copied: false }), 3500)
                )
              }
            >
              {copied ? '✔' : 'COPY'}
            </Button>

            {copied && (
              <Box
                css={css`
                  position: absolute;
                  right: 2px;
                  bottom: 6px;
                  color: ${COLORS.blue};
                `}
              >
                Copied!
              </Box>
            )}
          </Row>

          <Box px={4} pb={4}>
            <ul
              css={css`
                line-height: 140%;
              `}
            >
              <li>Be careful with the shared URL, and copy it immediately.</li>
              <li>Anyone with the shared URL can access its content.</li>
              <li>
                The share may only be accessed {storedSecret.secret.expires_views}{' '}
                {storedSecret.secret.expires_views === 1 ? 'time' : 'times'} and expires in{' '}
                {timeDifference(storedSecret.secret ? storedSecret.secret.expires_at : undefined)}.
              </li>
              <li>After this point the content will be permanently deleted.</li>
            </ul>
          </Box>

          <Row justifyContent="flex-end">
            <Box>
              <Button width={130} px={3} py={2} onClick={reset}>
                CREATE NEW
              </Button>
            </Box>
          </Row>
        </Column>
      </>
    )
  }
}
