import React from 'react'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import {
  // basic
  space,
  borders,
  borderRadius,
  borderColor,
  color,
  background,
  lineHeight,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  flex,
  order,
  alignSelf,
  fontSize,
  fontStyle,
  fontWeight,
  textAlign,
  position,
  top,
  right,
  bottom,
  left,
  zIndex,
  overflow,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
} from 'styled-system'

const COLORS = {
  text: '#596772',

  yellow: '#f5be00',
  yellow_dark: '#cc9e00',

  blue: '#448ce6',
  blue_dark: '#1a65c3',
}

const CssReset = () => (
  <Global
    styles={css`
      html {
        font-size: 13px;
        font-family: 'Montserrat', sans-serif;
        box-sizing: border-box;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      body {
        background-color: #fff;
        color: ${COLORS.text};
      }

      html,
      body,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ol,
      ul,
      li,
      dl,
      dt,
      dd,
      pre,
      blockquote,
      address {
        margin: 0;
        padding: 0;
      }

      input,
      textarea,
      button {
        font-size: 1rem;
        font-family: inherit;
        color: inherit;
      }
    `}
  />
)

const Box = styled('div')(
  space,
  borders,
  borderRadius,
  borderColor,
  color,
  background,
  lineHeight,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  flex,
  order,
  alignSelf,
  fontSize,
  fontStyle,
  fontWeight,
  textAlign,
  position,
  top,
  right,
  bottom,
  left,
  zIndex,
  overflow
)

const Row = styled(Box)(
  {
    display: 'flex',
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
)

const Column = styled(Row)({
  flexDirection: 'column',
})

const buttonVariants = {
  primary: css`
    background-color: ${COLORS.yellow};

    &:hover {
      background-color: ${COLORS.yellow_dark};
    }
  `,

  secondary: css`
    background-color: ${COLORS.blue};

    &:hover {
      background-color: ${COLORS.blue_dark};
    }
  `,

  transparent: css`
    padding: 0;
    border: none;
    background: transparent;
  `,
}

const Button = styled('button')(
  css`
    padding: 8px 12px;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
  `,
  ({ variant = 'primary' }) => buttonVariants[variant],
  ({ disabled }) =>
    disabled &&
    css`
      background-color: #ddd;
      pointer-events: none;
    `,
  fontSize,
  width,
  height,
  space
)

const Input = styled('input')(
  css`
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
  `,
  width,
  height,
  space
)

const Textarea = styled('textarea')`
  width: 100%;
  height: 170px;
  padding: 12px;
  resize: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-family: monospace;

  &::placeholder {
    color: #ccc;
  }
`

const Select = styled('select')`
  width: 100%;
  height: 30px;
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 3px;
`

const datePickerStyles = css`
  .react-datepicker {
    color: #595959;
    border: none;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25);
  }

  .react-datepicker__input-container {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }

  .react-datepicker__current-month {
    padding-bottom: 10px;
    font-weight: 500;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--keyboard-selected {
    color: #596772;
    background-color: #d2edfd;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: inherit;
  }

  .react-datepicker__day-names {
    border-top: 1px solid #eee;
  }

  .react-datepicker__time-container {
    border-left: 1px solid #eee;
  }

  .react-datepicker__time-container .react-datepicker__time {
    background: transparent;
    overflow: hidden;
  }
`

export {
  css,
  styled,
  COLORS,
  CssReset,
  Box,
  Row,
  Column,
  Button,
  Input,
  Textarea,
  Select,
  datePickerStyles,
}
