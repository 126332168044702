import React from 'react'
import { Router, navigate } from '@reach/router'
import { styled, CssReset, Box, Row, Column, Button } from './ui'
import Entry from './entry'
import Pickup from './pickup'

import logo from './assets/logo.png'
import 'react-datepicker/dist/react-datepicker.css'

const Logo = styled('img')`
  height: 24px;
`

const App = () => (
  <>
    <CssReset />

    <Row width={1} height={50} alignItems="center" bg="#596772" px={4}>
      <Box>
        <Button variant="transparent" onClick={() => navigate('/')}>
          <Logo src={logo} alt="Private Share" />
        </Button>
      </Box>
    </Row>

    <Column minHeight="100vh" maxWidth={630} mx="auto" px={4}>
      <Row
        justifyContent="space-between"
        pt={4}
        pb={3}
        mb={24}
        fontSize={14}
        borderBottom="1px solid #ccc"
      >
        <Box fontWeight="600">PRIVATE SHARE</Box>
      </Row>

      <Router>
        <Entry path="/" />
        <Pickup path="/pickup/:id" />
      </Router>
    </Column>
  </>
)

export default App
